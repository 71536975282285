<template>
  <v-app id="login">
    <v-main>
      <!-- <p style="padding:10px;"><v-toolbar-title>管理平台</v-toolbar-title></p> -->
      <v-container class="fill-height" fluid>
        <v-card class="d-inline-block mx-auto">
          <v-row >
              <v-col style="padding:0px 12px;">
                <v-img class="white--text" width="493"   src="./../assets/log.png">
                </v-img>
              </v-col>
              <v-col  class="text-center pl-0 form">
                <div  >
                  <router-view></router-view>
                </div>
              </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { post, tips, obj, setCookie } from '@/facade'
  export default {
    name: 'login',
    data() {
      return {
        valid: true,
        form: { type: 'email' }
      }
    },
    methods: {
      async login() {
        const value = this.$refs.form.validate()
        if (value) {
          const data = await post('/user/login', this.form)
          if (data.code == 'ok') {
            obj.token = data.token;
            setCookie('token', data.token, 1);
            obj.uid = data.uid;
            setCookie('uid', data.uid, 1);
            localStorage.setItem('name', JSON.stringify(this.form.key))
            this.$router.replace('/')
          } else {
            tips('error', data.message)
          }
        }

      }
    }
  }
</script>
<style scoped>
  #login {
    background: #e9ecef;
  }

  .form>div {
    width: 80%;

  }

  .form {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 500px;
  }
</style>